import React from 'react';
import OneColumnContent from "templates/one-column-content-layout";
import {Hero, Breadcrumb} from "components/ui";
import Layout from 'components/layout';
import SeoComponent from 'components/seo';

//Layout include hero component with one column content layout
const BasicPageLayout = ({title, description, keywords, location, children, ...rest}) => {
  return (
    <Layout location={location}>
    <SeoComponent title={title} description={description} keywords={keywords} />
    <Hero
        img={rest.img}
        color={rest.color}
        isFixedHeight={rest.fixedHeroHeight}
        >
            <Breadcrumb pathname={rest.pathname} customCurrentPage={rest.customCurrentPage}/>
            <h1 name="main-content" id="main-content" className="usa-hero__heading" aria-label={`Hero: ${title}`}>{title}</h1>
            {rest.description && <p>{rest.description}</p> }
        </Hero>
        <div className="padding-y-5">
            <OneColumnContent>
                {children}
            </OneColumnContent>
        </div>
    </Layout>

    );
};

export default BasicPageLayout;
