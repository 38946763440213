import React from 'react';

const OneColumnContent = ({children}) => {
  return (
     <div className="grid-container">
        <div className="grid-row grid-gap">
            <div className="desktop:grid-col-8 tablet:grid-col-12">
                {children}
            </div>
        </div>
    </div>
    );
};

export default OneColumnContent;