import React from 'react';
import BasicPageLayout from 'templates/basic-page-layout.js';
import { useStrapiAboutUsPageData } from "hooks";
import { getSrc } from "gatsby-plugin-image"

const isBrowser = typeof window !== "undefined"

const AboutUs=({location})=>{
        let thisLocationPathname = ''
        if (isBrowser) {
            thisLocationPathname = window.location.pathname;
        }
    const { strapiAbout } = useStrapiAboutUsPageData();
    const seo = {
      metaTitle: strapiAbout?.title,
      metaDescription: strapiAbout?.description,
      metaKeywords: strapiAbout?.keywords
    }
    return (
        <BasicPageLayout
            location={location}
            title={seo.metaTitle}
            description={seo.metaDescription}
            keywords={seo.metaKeywords}
            pathname={thisLocationPathname}
            customCurrentPage="About NCSACW"
            color="#f5f5f5"
            img={getSrc(strapiAbout?.hero_image?.background_image?.localFile)}
            fixedHeroHeight={false}
        >
            {strapiAbout?.blocks &&
                <div className="unordered-list-style"
                    dangerouslySetInnerHTML={{
                    __html: strapiAbout?.blocks[0]?.body?.data?.childMarkdownRemark?.html,
                    }}
                />
            }
        </BasicPageLayout>
    )
}
export default AboutUs;
